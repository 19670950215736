import React, {useState, useEffect} from "react"
import { navigate } from 'gatsby'
import Spinner from 'react-bootstrap/spinner'
import Layout from "src/components/layout"
import Button from 'src/components/shared/Button'
import SoftGreenCheckmarkBadge from 'src/components/shared/badges/SoftGreenCheckmarkBadge'
import MoveIcon from 'src/images/icons/move.svg'
import DeleteIcon from 'src/images/icons/trash-2.svg'
import SearchIcon from 'src/images/icons/duotone-search.svg'

const pageName = 'Fake Admin'
const locationURL = `${process.env.BASE_URL}/account`


const fakeProfilePhrasesResult = {
  term: 'marketing',
  resultCount: 6,
  title: 'Marketing Manager',
  phrases: [
    {
      category: 'Marketing',
      title: 'Digital Marketing Manager',
      phrase: 'Experienced Digital Marketing Manager with extensive experience building, maintaining, and running successful digital marketing campaigns.• Bringing forth broad marketing knowledge, coupled with focused campaign experience.• Adept at creating and implementing client-centered, successful campaigns, aimed at improving brand awareness and presence.• Collaborative and creative manager accomplished at managing digital marketing presence content.• Experienced in leading teams of marketing professionals to meet and exceed digital marketing goals.',
      subphrases: [
        {
          phrase: 'Experienced Digital Marketing Manager with extensive experience building, maintaining, and running successful digital marketing campaigns.'
        },
        {
          phrase: 'Bringing forth broad marketing knowledge, coupled with focused campaign experience.'
        },
        {
          phrase: 'Adept at creating and implementing client-centered, successful campaigns, aimed at improving brand awareness and presence.'
        },
        {
          phrase: 'Collaborative and creative manager accomplished at managing digital marketing presence content.'
        },
        {
          phrase: 'Experienced in leading teams of marketing professionals to meet and exceed digital marketing goals.'
        }
      ]
    },
    {
      category: 'Marketing Manager',
      title: 'Marketing Manager',
      phrase: 'Experienced and energetic Marketing Manager with over seven years of experience effectively managing marketing projects from conception to completion.• Adept in using digital marketing platforms to increase sales and overall company productivity.• Experienced in preparing and overseeing online and print marketing campaigns, resulting in an increase in partner relations for the company.• Adept in monitoring and reporting marketing objectives, to maintain necessary internal communications within the company.• Pragmatic and result oriented, I am determined to build market presence in the next company I join.',
      subphrases: [
        {
          phrase: 'Experienced and energetic Marketing Manager with over seven years of experience effectively managing marketing projects from conception to completion.'
        },
        {
          phrase: 'Adept in using digital marketing platforms to increase sales and overall company productivity.'
        },
        {
          phrase: 'Experienced in preparing and overseeing online and print marketing campaigns, resulting in an increase in partner relations for the company.'
        },
        {
          phrase: 'Adept in monitoring and reporting marketing objectives, to maintain necessary internal communications within the company.'
        },
        {
          phrase: 'Pragmatic and result oriented, I am determined to build market presence in the next company I join.'
        }
      ]
    }
  ]

}

const PhraseInputGroup = ({phrase}) => {
  const subphraseContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
  const baseIconStyle = {
    width: 20,
    color: '#869AB8',
  }
  const moveIconStyle = {
    ...baseIconStyle,
    marginRight: 10,
  }
  const deleteIconStyle = {
    ...baseIconStyle,
    marginLeft: 10,
  }
  return (
    <div className=" card-body form-group mb-5">
      <div className='mb-3'>
        <span>

          <span className='h3'>
            {` ${phrase.title}`}
          </span>
        </span>
      </div>
      {
        phrase.subphrases.map(subphrase => (
          <div style={subphraseContainerStyle}>
            <MoveIcon
              className='icon d-none'
              style={ moveIconStyle }
            />
            <textarea
              class="form-control"
              id="applyMessage"
              rows="2"
              value={ subphrase.phrase }
            />
            <DeleteIcon
              className='icon d-none'
              style={ deleteIconStyle }
            />
          </div>
        ))
      }
      <Button className='mt-3'>
        Save
      </Button>
    </div>
    )
}

const SearchBar = ({term, resultCount}) => {
  const filterPillStyle = {
    backgroundColor: 'rgba(80,102,144,.1)',
    color: '#506690',
    marginLeft: 5,
  }
  const selectedFilterPillStyle = {
    ...filterPillStyle,
    backgroundColor: 'var(--primary)',
    color: 'white',
  }
  return (
    <section className='my-6'>
      <div class="container">

          <div class="row">
            <div class="col-12">
              <form class="rounded shadow">
                <div class="input-group input-group-lg">
                  <span class="input-group-text border-0 pe-1">
                    <SearchIcon/>
                    <i class="fe fe-search"></i>
                  </span>
                  <input type="text"
                    class="form-control border-0 px-1"
                    aria-label="Search"
                    placeholder="Search"
                    value={term}
                  />
                  <span class="input-group-text border-0 py-0 ps-1 pe-3">
                    <span class="h6 text-uppercase text-muted d-none d-md-block mb-0 me-5 mr-3">
                      {`${resultCount} results`}
                    </span>
                    <button type="submit" class="btn btn-sm btn-primary">
                      Search
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-auto">
              <h6 class="fw-bold text-uppercase text-muted mb-0">
                Filtered by:
              </h6>
            </div>
            <div class="col ms-n5 mt-3 mb-3">
              <a class="badge rounded-pill bg-secondary-soft" href="blog-search.html" style={ selectedFilterPillStyle }>
                <span class="h6 text-uppercase">Profile</span>
              </a>
              <a class="badge rounded-pill bg-secondary-soft" href="blog-search.html" style={ filterPillStyle }>
                <span class="h6 text-uppercase">Work Experience</span>
              </a>
              <a class="badge rounded-pill bg-secondary-soft" href="blog-search.html" style={ filterPillStyle }>
                <span class="h6 text-uppercase">Skills</span>
              </a>
            </div>
          </div>

        </div>
       </section>
    )
}


const Header = ({currentUser}) => {
  return (
    <header className="bg-dark pt-4 pb-5 d-md-block">
      <div className="container-md">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="font-weight-bold text-white mb-2">
              Content Admin
            </h1>
          </div>
        </div>
      </div>
    </header>
    )
}


const FakeAdmin = () => {

  const {
    phrases,
  } = fakeProfilePhrasesResult

  const containerStyle = {
    // width: '100%',
    // height: '100%',
  }
  return (
    <Layout
      shouldForceLoginModalOpenIfNoCurrentUser={true}
    >
      <Header/>
      <SearchBar
        term={fakeProfilePhrasesResult.term}
        resultCount={fakeProfilePhrasesResult.resultCount}
      />
      <section>
        <div style={containerStyle} className='container'>
          <form>
            <div className='row'>
              <div className="col-12">
                {
                  phrases.map( phrase => (
                      <PhraseInputGroup
                        phrase={ phrase }
                      />
                    )
                  )
                }
              </div>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default FakeAdmin

