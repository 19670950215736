import React from "react"
import Spinner from 'react-bootstrap/spinner'

const Button = ({variant='primary', size, onClick, isLoading, className='', children, spinnerVariant='light', style,}) => {
  const variantClassMap = {
    'primary': 'btn-primary',
    'secondary': 'btn-secondary',
    'outline-primary': 'btn-outline-primary',
    'outline-secondary': 'btn-outline-secondary',
  }
  const sizeClassMap = {
    'xs': 'btn-xs',
    'sm': 'btn-sm',
    'lg': 'btn-lg',
  }
  const variantClassName = variantClassMap[variant]
  const sizeClassName = size ? sizeClassMap[size] : ''
  const _className = `btn ${variantClassName} ${sizeClassName} ${className}`
  const loadingSpinner = <Spinner animation="border" variant={spinnerVariant}/>
  const _children = isLoading ? loadingSpinner : children
  return (
    <button
      {...{
        onClick,
        style,
      }}
      className={_className}
      disabled={isLoading}
    >
      {_children}
    </button>
    )
}

export default Button
